import { useCallback } from 'react'
import {
  DataGridPro,
  GridColDef,
  GridRowModel,
  GridRow,
  GridRowParams,
} from '@mui/x-data-grid-pro'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { useTheme } from '@emotion/react'

import scss from './index.module.scss'

interface TreeDataGridProps<T extends GridRowModel> {
  loading: boolean
  error: Error
  columns: GridColDef[]
  itemActions?: Record<string, unknown>
  currentActiveItem: T
  list: T[]
  sortField: string
  sortOrder: boolean
  dataKey?: keyof T
}

const buildTreePath = <T extends GridRowModel>(row: T, data: T[]): string[] => {
  if (!row) return []
  const parent = data.find(r => r?.id === row?.parentId)
  return parent
    ? [...buildTreePath(parent, data), row.id as string]
    : [row.id as string]
}

const COLUMN_HEADER_HEIGHT = 42
const ROW_HEIGHT = 44

const TreeDataGrid = <T extends GridRowModel>({
  loading,
  itemActions = {},
  columns,
  sortField,
  sortOrder,
  currentActiveItem,
  list = [],
  dataKey = 'id',
}: TreeDataGridProps<T>): JSX.Element => {
  const theme = useTheme()
  const muiTheme = createTheme({
    palette: {
      primary: {
        main: theme.primary,
      },
      secondary: {
        main: theme.secondary,
      },
    },
    components: {
      MuiDataGrid: {
        styleOverrides: {
          root: {
            '--unstable_DataGrid-headWeight': 600,
            '--DataGrid-containerBackground': theme['secondary-50'],
            border: '1px solid #DFE0E1',
            fontFamily: 'Open Sans',
          },
          columnHeaders: {
            backgroundColor: theme['secondary-50'],
            fontSize: 10,
            boxShadow: '0px -1px 0px 0px #DEE0E3 inset',
            color: theme['secondary-700'],
            letterSpacing: 0.2,
            '.grouping-column-header': {
              fontSize: 0,
            },
            '.MuiDataGrid-columnHeader--sorted': {
              color: theme.primary,
              '& .MuiDataGrid-sortIcon': {
                color: theme.primary,
              },
            },
          },
          row: {
            fontSize: 12,
            letterSpacing: 0.24,
            color: theme['secondary-900'],
            backgroundColor: '#fff',
            '&.tree-child-row': {
              backgroundColor: theme['secondary-50'],
            },
            '&:hover': {
              backgroundColor: theme['secondary-light-500'],
            },
            '&.Mui-selected': {
              backgroundColor: theme['secondary-light-500'],
            },
          },
          cell: {
            '&.MuiDataGrid-cell:focus': {
              outline: 'none',
            },
            '&.MuiDataGrid-cell:focus-within': {
              outline: 'none',
            },
          },
        },
      },
    },
  })

  const CustomRow = useCallback(
    (props: GridRowParams<T>) => (
      <GridRow
        {...props}
        onMouseEnter={() => itemActions.onMouseEnter?.(props)}
        onMouseLeave={() => itemActions.onMouseLeave?.(props)}
      />
    ),
    [itemActions]
  )

  const getRowClassName = useCallback(
    (params: GridRowParams<T>): string => {
      if (params.id === currentActiveItem?.id) return 'Mui-selected'
      return params.row.parentId ? 'tree-child-row' : 'tree-parent-row'
    },
    [currentActiveItem]
  )

  const memoizedBuildTreePath = useCallback(
    (row: T) => buildTreePath(row, list),
    [list]
  )

  return (
    <ThemeProvider theme={muiTheme}>
      <div className={scss.container}>
        <DataGridPro
          rows={list}
          columnHeaderHeight={COLUMN_HEADER_HEIGHT}
          disableCellSelectionOnClick
          rowHeight={ROW_HEIGHT}
          sortingOrder={['asc', 'desc']}
          getRowClassName={getRowClassName}
          initialState={{
            sorting: {
              sortModel: [
                { field: sortField, sort: sortOrder ? 'asc' : 'desc' },
              ],
            },
          }}
          slots={{
            row: CustomRow,
          }}
          groupingColDef={{
            width: 50,
            headerClassName: 'grouping-column-header',
            resizable: false,
          }}
          onRowClick={itemActions.onRowClick}
          columns={columns}
          loading={loading}
          selectionModel={
            currentActiveItem?.[dataKey] ? [currentActiveItem?.[dataKey]] : []
          }
          getRowId={row => row[dataKey] as string}
          treeData
          hideFooter
          getTreeDataPath={memoizedBuildTreePath}
        />
      </div>
    </ThemeProvider>
  )
}

export default TreeDataGrid
