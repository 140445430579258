// libraries
import { memo, ReactElement, ReactNode, Dispatch, SetStateAction } from 'react'
import _ from 'lodash'

// constants
import { THEMES } from 'constants/colour'

// components
import {
  EmptyList,
  Loading,
  EmptySearch,
  ListErrorHandler,
  TreeDataGrid,
} from 'components/common'

import type { ListConditions } from 'types/common'
import type { Entity } from 'types/entity'
import {
  TreeTableActions,
  TreeTableComponentProps,
} from 'components/common/TreeTable/types'

type ListProps<T extends Entity = Entity> = Partial<
  TreeTableComponentProps<T>
> & {
  title: string
  options?: ListConditions
  list?: T[]
  listItem: ReactNode
  setListConditions?: Dispatch<SetStateAction<ListConditions>>
  emptyState: ReactElement
}

const ListTree = memo(
  ({
    title,
    options: { ascOrder, isSearching, ...otherOptions } = {},
    list = [],
    emptyState,
    itemActions,
    tableLoading,
    ...rest
  }: ListProps): ReactElement => {
    if (_.isEmpty(list)) {
      return isSearching ? (
        <EmptySearch />
      ) : (
        <EmptyList content={title} theme={THEMES.light} render={emptyState} />
      )
    }

    return (
      <TreeDataGrid
        {...rest}
        {...otherOptions}
        list={list}
        loading={tableLoading}
        itemActions={itemActions as TreeTableActions<unknown>}
      />
    )
  }
)

const TreeListDataGridPro = ({
  loading,
  onCancel,
  error,
  ...rest
}: ListProps & {
  loading?: boolean
  onCancel?: () => void
  error?: Error
}): ReactElement => (
  <ListErrorHandler error={error}>
    {loading ? (
      <Loading onClick={onCancel} buttonContent='Pause Loading' />
    ) : (
      <ListTree {...rest} />
    )}
  </ListErrorHandler>
)

export default TreeListDataGridPro
