import _ from 'lodash'

import { APP_NAMES } from 'constants/common'
import {
  VentingEventType,
  EmissionObservation,
} from 'app/MissionControlMethaneSolution/types/graphql'
import { getConnectedStringFromArray } from 'helpers/utils'

export type Tab = { label: string; value: string; route: string }

export type Tabs = Tab[]

export const getTabRoute = (tabs?: Tabs, tab?: string): string => {
  const newTab = (_.find(tabs, { value: tab }) ?? _.first(tabs)) as Tab
  return _.get(newTab, 'route')
}

export const getTabView = (tabs: Tabs, route?: string): string => {
  const newTab = _.find(tabs, { route }) as Tab
  return _.get(newTab, 'value')
}

export const makeMethaneAppEntityName = (entityName: string): string =>
  `${APP_NAMES.methane}_${entityName}`

/**
 * For some tables, we want to show the 'whole content' loader only on the first request.
 * On filtering and sorting, we want to show a loader inside the table so the content stays.
 *  */
export const getListLoadingProp = ({
  isFirstRequestDone,
  loading,
}: {
  isFirstRequestDone?: boolean
  loading?: boolean
}): { tableLoading?: boolean } | { loading?: boolean } =>
  isFirstRequestDone ? { tableLoading: loading } : { loading }

export const roundToDecimalPlaces = (
  value: number | string,
  decimalPlaces = 2
): number => {
  if (_.isNil(value)) return value
  return Number(parseFloat(String(value)).toFixed(decimalPlaces))
}

export const getTableHeaderWithUnit = (header: string, unit: string): string =>
  `${header} (${unit})`

type BlobPart = string | Uint8Array | Blob | ArrayBuffer
export const downloadFile = (
  content: BlobPart,
  fileName: string,
  type = 'application/octet-stream'
) => {
  const blob = new Blob([content], { type })
  const url = URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = url
  link.download = fileName
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
  URL.revokeObjectURL(url)
}

// Note: The actual output might vary based on locale
export const getFormattedNumber = (
  value?: number | string | null,
  locale: string = 'en-CA',
  fractionDigits = { minimumFractionDigits: 0, maximumFractionDigits: 4 }
): string => {
  if (_.isEmpty(_.toString(value))) return ''

  const numericValue = _.toNumber(value)

  return _.isNaN(numericValue)
    ? ''
    : numericValue.toLocaleString(locale, fractionDigits)
}

export const formatNumber = (
  value?: number | string | null,
  decimalPlaces?: number
): string => {
  decimalPlaces = decimalPlaces ?? 2
  return parseFloat(String(value)) !== 0
    ? getFormattedNumber(value, 'en-CA', {
        minimumFractionDigits: 0,
        maximumFractionDigits: decimalPlaces,
      })
    : String(value)
}

export const DECIMAL_PLACES_BY_KEYS = {
  emissionsRate: 2,
  emissionsVolume: 2,
  siteDailyCh4Emissions: 3, // for all site daily emissions. it's not a key
  totalEmissions: 0,
  totalEmission: {
    bottomUpTotal: 0,
    knownEventsTotal: 0,
    measuredTotal: 0,
  },
  methaneIntensity: 4,
  gasProductionMcf: 0,
  oilProductionBbl: 0,
  co2eEmission: 0,
  co2eIntensity: 4,
  emissionTotal: 0,
  uncertainty: 3,
  siteLevelEmission: 3,
  sourceLevelEmission: 3,
  siteLevelEmissionEstimation: 3,
  upperUncertainty: 3,
  lowerUncertainty: 3,
  discrepancyPercent: 2,
}

type DecimalPlacesByKeys =
  | keyof typeof DECIMAL_PLACES_BY_KEYS
  | 'totalEmission.bottomUpTotal'
  | 'totalEmission.knownEventsTotal'
  | 'totalEmission.measuredTotal'

export const formatNumberByKey = (
  value?: number | null,
  key?: DecimalPlacesByKeys
) => {
  return formatNumber(
    value,
    (_.get(DECIMAL_PLACES_BY_KEYS, key ?? '') as number) ?? 0
  )
}

export const roundNumberByKey = (value: number, key?: string): number => {
  const decimalPlaces = _.get(DECIMAL_PLACES_BY_KEYS, key || '', 0)
  const formattedValue = _.round(value, decimalPlaces)
  return _.isNaN(formattedValue) ? 0 : formattedValue
}

export const getKnownEventsType = (
  knownEvents?: EmissionObservation[]
): string => {
  const hasFlaring = _.some(knownEvents, [
    'emissionObservation.ventingEventType',
    VentingEventType.Flaring,
  ])
  const hasVenting = _.some(knownEvents, [
    'emissionObservation.ventingEventType',
    VentingEventType.Venting,
  ])
  const hasBlowdown = _.some(knownEvents, [
    'emissionObservation.blowdown',
    true,
  ])
  const result = _.compact([
    hasFlaring ? 'flaring' : '',
    hasVenting ? 'venting' : '',
    hasBlowdown ? 'blowdown' : '',
  ])
  return _.capitalize(getConnectedStringFromArray(result))
}
